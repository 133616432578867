/**
 * Created by Robin on 03/03/2023.
 */

import React, {useContext, useState, useEffect, useRef} from "react";

import { useReactToPrint } from 'react-to-print';

import APIContext from "../../contexts/APIContext.js";

import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import PriceField from '../PriceField/index';

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import PaymentIcon from '@mui/icons-material/Payment';
import EuroIcon from '@mui/icons-material/Euro';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PrintIcon from '@mui/icons-material/Print';

import PrintReceipt from './../PrintReceipt';

function ReceiptViewDialog(props) {
    const API = useContext(APIContext);
    const printContent = useRef(null);
    const [receipt, setReceipt] = useState(null);
    const [footer, setFooter] = useState(null);

    useEffect(() => {
        if (props.open) {
            API.getReceipt(props.id).then((receipt) => {
                setReceipt(receipt);
            });

            API.getFooter().then((footer) => {
                if (footer) {
                    setFooter(footer);
                }
            });
        }
    }, [props.id, props.open]);

    const handlePrint = useReactToPrint({
        content: () => printContent.current,
    });

    const formatValue = (value) => {
        return new Intl.NumberFormat('fr-BE', { style: 'decimal', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
    };

    const calculateChange = () => {
        let change = parseFloat(receipt.total_rounded);

        for (let payment of receipt.payments) {
            change = change - parseFloat(payment.amount);
        }

        return Math.abs(change);
    };

    const renderLines = () => {
        let items = [];

        for (let line of receipt.receipt_lines) {
            items.push(
                <TableRow key={line.id}>
                    <TableCell>
                        {line.productGroupInternalNo}
                    </TableCell>
                    <TableCell>
                        {line.qty}
                    </TableCell>
                    <TableCell>
                        € {formatValue(line.unit_price)}
                    </TableCell>
                    <TableCell>
                        {formatValue(line.discount_percentage)} %
                    </TableCell>
                    <TableCell>
                        € {formatValue(line.line_total)}
                    </TableCell>
                </TableRow>
            );
        }

        return items;
    };

    if (receipt) {
        return (
            <Dialog open={props.open} fullWidth={true} maxWidth={false}
                    PaperProps={{style: {maxHeight: '90vh', minHeight: '90vh'}}} onClose={props.onClose}>
                <DialogActions>
                    <Button onClick={() => props.onClose()}>Sluiten</Button>
                </DialogActions>
                <DialogContent style={{height: '100%', display: 'flex'}}>
                    <Grid container spacing={4}>
                        <Grid item xs={9}>
                            <Typography variant="h4">{receipt.no}</Typography>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Artikel</TableCell>
                                        <TableCell>Aantal</TableCell>
                                        <TableCell>Eenheidsprijs</TableCell>
                                        <TableCell>Korting (%)</TableCell>
                                        <TableCell>Subtotaal</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {renderLines()}
                                </TableBody>
                            </Table>
                        </Grid>
                        <Grid item xs={3}>
                            <Grid container style={{width: '100%'}} direction="column">
                                <Grid item>
                                    <Typography variant="h5">Totalen</Typography>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell variant="head">
                                                    Datum
                                                </TableCell>
                                                <TableCell>
                                                    {new Date(receipt.closingDate).toLocaleString('nl-BE')}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell variant="head">
                                                    Totaal zonder korting
                                                </TableCell>
                                                <TableCell>
                                                    € {formatValue(receipt.total_without_discount)}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell variant="head">
                                                    Korting
                                                </TableCell>
                                                <TableCell>
                                                    {receipt.discount_percentage} %
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell variant="head">
                                                    Totaal
                                                </TableCell>
                                                <TableCell>
                                                    € {formatValue(receipt.total_rounded)}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    <br />
                                    <br />
                                    <Typography variant="h5">Betalingen</Typography>
                                    <Table>
                                        <TableBody>
                                        {receipt.payments.map((payment) => {
                                            return (
                                                <TableRow key={payment.id}>
                                                    <TableCell variant="head">{payment.payment_method}</TableCell>
                                                    <TableCell align="right">€ {formatValue(payment.amount)}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                        </TableBody>
                                    </Table>
                                    <br />
                                    <br />
                                    <Grid container>
                                        <Grid item style={{flex: 1}}>
                                            <Typography variant="h5">Wisselgeld:</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h5" align="right">€ {formatValue(calculateChange())}</Typography>
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <br />
                                    <Button variant="contained" startIcon={<PrintIcon />} onClick={handlePrint} fullWidth>Herafdrukken</Button>
                                    <Box style={{display: 'none'}}>
                                        <PrintReceipt receipt={receipt} contentRef={printContent} footer={footer}/>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    } else {
        return [];
    }
}

export default ReceiptViewDialog