/**
 * Created by Robin on 27/02/2023.
 */

import React, {useState} from "react";
import axios from "axios";
import Cookies from "js-cookie";

import APIContext from './../contexts/APIContext.js';

//const API_URL = "http://127.0.0.1:7410";
const API_URL = "https://api.kassa.tjiep.be";

function API(props) {
    const [sessionToken, setSessionToken] = useState(null);
    const [isManager, setIsManager] = useState(false);

    const isLoggedIn = () => {
        return !!sessionToken;
    };

    const authenticate = async (name, password) => {
        const res = await _post('/auth', {
            name: name,
            password: password
        }, {} , false);

        if (res && res.data && res.data.status === 'success') {
            setSessionToken(res.data.token);

            if (res.data.manager) {
                setIsManager(true);
            } else {
                setIsManager(false);
            }

            return true;
        } else {
            return false;
        }
    };

    const isPosUser = () => {
        return !isManager
    };

    const signOut = async () => {
        setSessionToken(null);
    };

    const getProfile = async () => {
        const res = await _get('/profile');

        if (res && res.data && res.data.status === 'success') {
            return res.data.data;
        } else {
            return false;
        }
    };

    const searchProducts = async(searchValue) => {
        const res = await _get('/product?search=' + encodeURIComponent(searchValue));

        if (res && res.data && res.data.status === 'success') {
            return res.data.data;
        } else {
            return false;
        }
    };

    const getActiveReceipt = async(cartNo) => {
        const res = await _get('/register/active/' + cartNo);

        if (res && res.data && res.data.status === 'success') {
            return res.data.data;
        } else {
            return false;
        }
    };

    const getReceipt = async(receiptId) => {
        const res = await _get('/register/' + receiptId);

        if (res && res.data && res.data.status === 'success') {
            return res.data.data;
        } else {
            return false;
        }
    };

    const updateReceipt = async (receiptId, data) => {
        const res = await _post('/register/' + receiptId, data);

        if (res && res.data && res.data.status === 'success') {
            return res.data.data;
        } else {
            return false;
        }
    };

    const updateReceiptLine = async (receiptId, lineId, key, value) => {
        const res = await _post('/register/' + receiptId + '/' + lineId, {[key]: value});

        if (res && res.data && res.data.status === 'success') {
            return res.data.data;
        } else {
            return false;
        }
    };

    const deleteReceiptLine = async (receiptId, lineId) => {
        const res = await _delete('/register/' + receiptId + '/' + lineId);

        if (res && res.data && res.data.status === 'success') {
            return res.data.data;
        } else {
            return false;
        }
    };

    const createReceiptLine = async (receiptId, productGroupInternalNo) => {
        const res = await _post('/register/' + receiptId + '/new', {productGroupInternalNo: productGroupInternalNo});

        if (res && res.data && res.data.status === 'success') {
            return res.data.data;
        } else {
            return false;
        }
    };

    const getReceiptsToday = async () => {
        const res = await _get('/register/list/today');

        if (res && res.data && res.data.status === 'success') {
            return res.data.data;
        } else {
            return false;
        }
    };

    const getReceiptsForDate = async (date) => {
        const res = await _get('/register/list/' + date);

        if (res && res.data && res.data.status === 'success') {
            return res.data.data;
        } else {
            return false;
        }
    };

    const addPaymentToReceipt = async (receiptId, paymentMethod, amount) => {
        const res = await _post('/register/' + receiptId + '/payment', {amount: amount, payment_method: paymentMethod});

        if (res && res.data && res.data.status === 'success') {
            return res.data.data;
        } else {
            return false;
        }
    };

    const removePaymentFromReceipt = async (receiptId, paymentId) => {
        const res = await _delete('/register/' + receiptId + '/payment/' + paymentId);

        if (res && res.data && res.data.status === 'success') {
            return res.data.data;
        } else {
            return false;
        }
    };

    const getAllProducts = async () => {
        const res = await _get('/product/all');

        if (res && res.data && res.data.status === 'success') {
            return res.data.data;
        } else {
            return false;
        }
    };

    const createProduct = async (no, name, active, consignment) => {
        const res = await _post('/product', {internal_no: no, name, active, consignment});

        if (res && res.data && res.data.status === 'success') {
            return res.data.data;
        } else {
            return false;
        }
    };

    const updateProduct = async (no, name, active, consignment) => {
        const res = await _post('/product/' + no, {name, active, consignment});

        if (res && res.data && res.data.status === 'success') {
            return res.data.data;
        } else {
            return false;
        }
    };

    const getHistory = async (date, shopId) => {
        let params = {
            date: date,
        };

        if (shopId) {
            params.shop = shopId;
        }

        const res = await _get('/history', params);

        if (res && res.data && res.data.status === 'success') {
            return res.data.data;
        } else {
            return false;
        }
    };

    const getProductHistory = async (startDate, endDate, shopId) => {
        let params = {
            startDate: startDate,
            endDate: endDate,
        };

        if (shopId) {
            params.shop = shopId;
        }

        const res = await _get('/history/product', params);

        if (res && res.data && res.data.status === 'success') {
            return res.data.data;
        } else {
            return false;
        }
    };

    const getShops = async () => {
        const res = await _get('/shop');

        if (res && res.data && res.data.status === 'success') {
            return res.data.data;
        } else {
            return false;
        }
    };

    const getFooter = async () => {
        const res = await _get('/config/footer');

        if (res && res.data && res.data.status === 'success') {
            return res.data.data;
        } else {
            return false;
        }
    };

    const setFooter = async (value) => {
        const res = await _post('/config/footer', {value: value});

        if (res && res.data && res.data.status === 'success') {
            return res.data.data;
        } else {
            return false;
        }
    };

    const getShopDailyTotals = async (shopId) => {
        const res = await _get('/shop/totals/today/' + shopId);

        if (res && res.data && res.data.status === 'success') {
            return res.data.data;
        } else {
            return false;
        }
    };

    const getShopDailyTotalsForDate = async (shopId, date) => {
        const res = await _get('/shop/totals/' + date + '/' + shopId);

        if (res && res.data && res.data.status === 'success') {
            return res.data.data;
        } else {
            return false;
        }
    };

    const getShopTotalsForDate = async (date) => {
        const res = await _get('/shop/totals/' + date);

        if (res && res.data && res.data.status === 'success') {
            return res.data.data;
        } else {
            return false;
        }
    };

    const getShopTotalsToday = async () => {
        const res = await _get('/shop/totals/today');

        if (res && res.data && res.data.status === 'success') {
            return res.data.data;
        } else {
            return false;
        }
    };

    const getShopTotals = async () => {
        const res = await _get('/shop/totals');

        if (res && res.data && res.data.status === 'success') {
            return res.data.data;
        } else {
            return false;
        }
    };

    const getProductTotals = async (product_no) => {
        const res = await _get('/product/stats/' + product_no);

        if (res && res.data && res.data.status === 'success') {
            return res.data.data;
        } else {
            return false;
        }
    };

    const _get = async (path, params = {}, authenticated = true) => {
        try {
            const res = await axios({
                    method: 'get',
                    url: API_URL + path,
                    params: params,
                    headers: {
                        Authorization: authenticated ? "Basic " + btoa(sessionToken) : undefined
                    }
                }
            );

            return res;
        } catch (ex) {
            if (ex.response?.status === 401) {
                setSessionToken(null);
                return false;
            }
        }
    };


    const _post = async (path, data = {}, params = {}, authenticated = true) => {
        try {
            const res = await axios({
                method: 'post',
                url: API_URL + path,
                params: params,
                data: data,
                headers: {
                    Authorization: authenticated ? "Basic " + btoa(sessionToken) : undefined
                }
            });

            return res;
        } catch (ex) {
            if (ex.response?.status === 401) {
                setSessionToken(null);
                return false;
            }
        }
    };

    const _delete = async (path, params = {}, authenticated = true) => {
        try {
            const res = await axios({
                    method: 'delete',
                    url: API_URL + path,
                    params: params,
                    headers: {
                        Authorization: authenticated ? "Basic " + btoa(sessionToken) : undefined
                    }
                }
            );

            return res;
        } catch (ex) {
            if (ex.response?.status === 401) {
                setSessionToken(null);
                return false;
            }
        }
    };

    return (
        <APIContext.Provider value={{
            isLoggedIn,
            authenticate,
            getProfile,
            signOut,
            isPosUser,

            searchProducts,
            getAllProducts,
            createProduct,
            updateProduct,
            getProductTotals,

            getReceiptsToday,
            getReceiptsForDate,

            getActiveReceipt,
            getReceipt,
            updateReceipt,
            updateReceiptLine,
            createReceiptLine,
            deleteReceiptLine,
            addPaymentToReceipt,
            removePaymentFromReceipt,

            getHistory,
            getProductHistory,

            getShops,
            getShopTotalsToday,
            getShopTotalsForDate,
            getShopTotals,
            getShopDailyTotals,
            getShopDailyTotalsForDate,

            getFooter,
            setFooter,
        }}>
            {props.children}
        </APIContext.Provider>
    );
}

export default API;
