/**
 * Created by Robin on 07/03/2023.
 */

import React, {useContext, useState, useEffect, useRef} from "react";

import APIContext from "./../contexts/APIContext.js";

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import ProductLine from './../components/ProductLine';
import RegisterDrawerContent from './../components/RegisterDrawerContent';
import CheckoutDialog from './../components/CheckoutDialog';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';

function ReceiptFooterConfig(props) {
    const API = useContext(APIContext);
    const [footer, setFooter] = useState("");
    const [successAlert, setSuccessAlert] = useState(null);

    useEffect(() => {
        API.getFooter().then((footer) => {
           setFooter(footer);
        });
    }, []);

    const saveFooter = () => {
        API.setFooter(footer).then(() => {
            if (footer) {
                setFooter(footer);
                setSuccessAlert("Voetnoot opgeslagen!")
            }
        });
    };

    return (
        <Card>
            <CardHeader title="Rekening voettekst" />
            <CardContent>
                <Grid container spacing={2} direction="row" alignItems="center">
                    <Grid item style={{flex: 1}}>
                        <TextField
                            value={footer}
                            onChange={(e) => {setFooter(e.target.value)}}
                            fullWidth
                        />
                    </Grid>
                    <Grid item>
                        <Button variant="contained" style={{height: '100%'}} onClick={saveFooter}><SaveIcon /></Button>
                    </Grid>
                </Grid>
            </CardContent>
            <Snackbar
                open={!!successAlert}
                autoHideDuration={6000}
                onClose={() => (setSuccessAlert(null))}
            >
                <Alert
                    severity="success"
                    sx={{width: '100%'}}
                >
                    {successAlert}
                </Alert>
            </Snackbar>
        </Card>
    );
}

export default ReceiptFooterConfig;