/**
 * Created by Robin on 03/03/2023.
 */

import React, {useContext, useState, useEffect, useRef} from "react";

import { useNavigate } from "react-router-dom";

import APIContext from "./../../contexts/APIContext.js";

import Avatar from '@mui/material/Avatar';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import LogoutIcon from '@mui/icons-material/Logout';

function UserAvatar(props) {
    const API = useContext(APIContext);
    const [profile, setProfile] = useState(null);
    const [anchorRef, setAnchorRef] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        API.getProfile().then((profile) => {
            setProfile(profile);
        })
    }, [API]);

    const handleClick = (e) => {
        setAnchorRef(e.currentTarget)
    };

    const handleClose = () => {
        setAnchorRef(null);
    };

    const signOut = async () => {
        handleClose();
        await API.signOut();
        navigate('/');
    };

    if (profile) {
        return (
            <React.Fragment>
                <Grid container direction="row" justifyItems="center" alignItems="center" spacing={1} style={{cursor: 'pointer'}} onClick={handleClick}>
                    <Grid item>
                        <Avatar alt={profile.name}/>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2">
                            <b>{profile.name}</b>
                        </Typography>
                    </Grid>
                </Grid>
                <Menu
                    anchorEl={anchorRef}
                    open={!!anchorRef}
                    onClose={handleClose}
                >
                    <MenuItem onClick={signOut} disableRipple>
                        <LogoutIcon />
                        Afmelden
                    </MenuItem>
                </Menu>
            </React.Fragment>
        )
    } else {
        return [];
    }
}

export default UserAvatar;