import './App.css';

import API from './services/API.js';
import defaultTheme from './themes/default.js';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { ThemeProvider } from '@mui/material/styles';
import AppRouter from './AppRouter.js';

function App() {
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <API>
                <ThemeProvider theme={defaultTheme}>
                    <AppRouter />
                </ThemeProvider>
            </API>
        </LocalizationProvider>
    );
}

export default App;
