/**
 * Created by Robin on 28/02/2023.
 */

import React, {useContext, useState, useEffect} from "react";

import APIContext from "./../../contexts/APIContext.js";

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import PercentIcon from '@mui/icons-material/Percent';

import PercentPickerDialog from './PercentPickerDialog.js';

function DiscountSelector(props) {
    const API = useContext(APIContext);

    const [showDialog, setShowDialog] = useState(false);
    const [value, setValue] = useState(0);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const innerSetValue = (value) => {
        setValue(value);

        if (typeof props.onChange === 'function') {
            props.onChange(value);
        }
    };

    const openPercentPicker = () => {
        setShowDialog(true);
    };

    return (
        <React.Fragment>
            <TextField
                InputProps={{
                    inputProps: {
                        style: {textAlign: 'right'}
                    },
                    endAdornment: <PercentIcon fontSize="small"/>,
                    readOnly: true
                }}
                onClick={openPercentPicker}
                value={value}
            />
            {showDialog && <PercentPickerDialog open={showDialog} onClose={() => setShowDialog(false)} onChange={(value) => innerSetValue(value)} />}
        </React.Fragment>
    );
}

export default DiscountSelector;