/**
 * Created by Robin on 07/03/2023.
 */

import React, {useContext, useState, useEffect} from "react";

import moment from 'moment';
import 'moment/locale/nl';

import { useTheme } from '@mui/material/styles';

import APIContext from "./../contexts/APIContext.js";

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import HistoryLine from './../components/HistoryLine';
import ShopSelector from './../components/ShopSelector';
import CheckoutDialog from './../components/CheckoutDialog';

import PaymentIcon from '@mui/icons-material/Payment';
import EuroIcon from '@mui/icons-material/Euro';
import SearchIcon from '@mui/icons-material/Search';

moment.updateLocale("nl", {
    week: {
        dow: 1
    }
});

function ManagerProductStats(props) {
    const theme = useTheme();
    const API = useContext(APIContext);
    const [date, setDate] = useState(moment());
    const [startDate, setStartDate] = useState(moment('2023-03-01'));
    const [endDate, setEndDate] = useState(moment());
    const [selectedShop, setSelectedShop] = useState("");
    const [onlyConsignment, setOnlyConsignment] = useState(false);
    const [filter, setFilter] = useState("");

    const [products, setProducts] = useState([]);

    useEffect(() => {
        API.getProductHistory(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'), selectedShop !== "" ? selectedShop : undefined).then((stats) => {
           setProducts(stats);
        });
    },[]);

    useEffect(() => {
        API.getProductHistory(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'), selectedShop !== "" ? selectedShop : undefined).then((stats) => {
            setProducts(stats);
        });
    },[startDate, endDate, selectedShop]);

    const formatValue = (value) => {
        return new Intl.NumberFormat('fr-BE', { style: 'decimal', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
    };

    const renderLines = () => {
        let items = [];

        let product_nos = Object.keys(products).sort();


        for (let no of product_nos) {
            if (onlyConsignment) {
                if (products[no].consignment === false) {
                    continue;
                }
            }

            if (!(no.includes(filter) || products[no].name.includes(filter))) {
                continue;
            }

            items.push(
                <TableRow key={no}>
                    <TableCell>{no}</TableCell>
                    <TableCell>{products[no].name}</TableCell>
                    <TableCell align="right">{products[no].qty}</TableCell>
                    <TableCell align="right">€ {formatValue(products[no].amount)}</TableCell>
                </TableRow>
            );
        }


        return items;
    };

    return (
        <Card>
            <CardHeader
                title="Product statistieken"
                action={
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <FormControlLabel control={<Checkbox checked={onlyConsignment} onChange={() => setOnlyConsignment(!onlyConsignment)} />} label="Enkel consignaties" />
                        </Grid>
                        <Grid item>
                            <TextField
                                size="small"
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <SearchIcon />
                                    )}}
                            />
                        </Grid>
                        <Grid item>
                            <Box style={{width: '200px'}}>
                                <ShopSelector value={selectedShop} onChange={(e) => setSelectedShop(e.target.value)}/>
                            </Box>
                        </Grid>
                        <Grid item>
                            <DatePicker label="Start" format="DD/MM/YYYY" value={startDate} onChange={(value) => setStartDate(value)}/>
                        </Grid>
                        <Grid item>
                            <DatePicker label="Einde" format="DD/MM/YYYY" value={endDate} onChange={(value) => setEndDate(value)}/>
                        </Grid>
                    </Grid>
                }
            />
            <CardContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>No</TableCell>
                            <TableCell>Naam</TableCell>
                            <TableCell align="right">Aantal</TableCell>
                            <TableCell align="right">Bedrag</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderLines()}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    );
}

export default ManagerProductStats