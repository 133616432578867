/**
 * Created by Robin on 06/03/2023.
 */

import React, {useContext, useState, useEffect} from "react";
import {Link, Outlet, useParams} from "react-router-dom";

import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import HistoryIcon from '@mui/icons-material/History';
import MenuIcon from '@mui/icons-material/Menu';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import BarChartIcon from '@mui/icons-material/BarChart';
import ReceiptIcon from '@mui/icons-material/Receipt';
import GridViewIcon from '@mui/icons-material/GridView';

import UserAvatar from './../components/UserAvatar';

const drawerWidth = 240;

function ManagerPage(props) {
    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <div>
            <CssBaseline />
            <Drawer
                variant="temporary"
                sx={{
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
                open={menuOpen}
                onClose={() => {setMenuOpen(false)}}
                style={{ zIndex: 1252 }}
                anchor="right"
            >
                <Grid container alignItems="center" justifyContent="center" style={{marginTop: 30}}>
                    <Grid item>
                        <Typography variant="h2" component="h1">TJIEP</Typography>
                    </Grid>
                </Grid>
                <List onClick={() => setMenuOpen(false)}>
                    <ListItem>
                        <Typography variant="subtitle2" color="secondary">
                            Statistieken
                        </Typography>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton component={Link} to="/">
                            <ListItemIcon>
                                <GridViewIcon />
                            </ListItemIcon>
                            <ListItemText primary="Dashboard"/>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton component={Link} to="/history">
                            <ListItemIcon>
                                <HistoryIcon />
                            </ListItemIcon>
                            <ListItemText primary="Dagtotalen"/>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton component={Link} to="/stats">
                            <ListItemIcon>
                                <BarChartIcon />
                            </ListItemIcon>
                            <ListItemText primary="Product totalen"/>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton component={Link} to="/month">
                            <ListItemIcon>
                                <BarChartIcon />
                            </ListItemIcon>
                            <ListItemText primary="Maandstatistieken"/>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <Typography variant="subtitle2" color="secondary">
                            Beheer
                        </Typography>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton component={Link} to="/product">
                            <ListItemIcon>
                                <CheckroomIcon />
                            </ListItemIcon>
                            <ListItemText primary="Producten"/>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton component={Link} to="/footer">
                            <ListItemIcon>
                                <ReceiptIcon />
                            </ListItemIcon>
                            <ListItemText primary="Rekening voettekst"/>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
            <Box sx={{
                width: `100%`,
            }}>
                <AppBar position="fixed" style={{ zIndex: 1251 }}>
                    <Toolbar sx={{
                        width: `100%`,
                    }}>
                        <Grid container direction="row" style={{height: '64px'}} alignItems="center">
                            <Grid item style={{width: 375}}>
                                <UserAvatar />
                            </Grid>
                            <Grid item style={{flex: 1}}>
                            </Grid>
                            <Grid item>
                                <IconButton onClick={() => setMenuOpen(true)}>
                                    <MenuIcon/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <Toolbar />
                <Box sx={{
                    p: 3,
                    backgroundColor: '#F1F5F9'
                }}>
                    <Outlet />
                </Box>
            </Box>
        </div>
    )
}

export default ManagerPage;