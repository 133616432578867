/**
 * Created by Robin on 27/02/2023.
 */

import { createTheme } from '@mui/material/styles';

const palette = {
    background: {
        default: '#F1F5F9'
    },
    secondary: {
        //main: '#111827'
        main: '#03a9f4'
    },
    primary: {
        //main: '#4f46e5'
        main: '#00537e'
    },
    disabled: {
        main: '#999'
    },
    border: {
        main: '#aaa',
        lighter: '#eee'
    }
};

const components = {
    MuiTypography: {
        variants: [
            {
                props: {variant: 'avatar'},
                style: {
                    fontWeight: 800,
                    fontSize: 14
                }
            }
        ]
    },
    MuiList: {
        variants: [
            {
                props: { variant: 'mailbox' },
                style: {
                    color: '#111827',
                    paddingTop: 0,
                    paddingBottom: 0,
                    "& .MuiListItem-root": {
                        paddingLeft: 12,
                        paddingRight: 12,
                        paddingBottom: 0,
                        paddingTop: 0,
                        "&:hover": {
                            color: palette.primary.main,
                            cursor: 'pointer'
                        }
                    },
                    "& .MuiListItemIcon-root": {
                        minWidth: 20,
                        color: "inherit"
                    },
                    "& .MuiDivider-root": {
                        backgroundColor: "currentColor",
                        opacity: 0.3
                    },
                    "& .MuiListItemText-primary": {
                        fontSize: 13
                    },
                    "& .MuiListItemText-secondary": {
                        fontSize: 11,
                        color: '#6591B5'
                    }
                }
            },
            {
                props: { variant: 'maillist' },
                style: {
                    color: '#111827',
                    paddingTop: 0,
                    paddingBottom: 0,
                    "& .MuiListItem-root": {
                        fontSize: 13,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingTop: 8,
                        "&:hover": {
                            color: palette.primary.main,
                            cursor: 'pointer'
                        },
                        borderBottom: '1px solid ' + palette.border.main,
                    },
                    "& .MuiListItemIcon-root": {
                        minWidth: 20,
                        color: "inherit"
                    },
                    "& .MuiDivider-root": {
                        backgroundColor: "currentColor",
                        opacity: 0.3
                    },
                    "& .MuiListItemText-primary": {
                        fontSize: 13
                    },
                    "& .MuiListItemText-secondary": {
                        fontSize: 11,
                        color: '#6591B5'
                    }
                }
            }
        ],

    },
    MuiAppBar: {
        styleOverrides: {
            root: {
                backgroundColor: '#ffffff',
                color: '#111827',
            }
        }
    },
    MuiDrawer: {
        styleOverrides: {
            paper: {
                backgroundColor: '#111827',
                color: '#B8BABF',
                "& .MuiListItemIcon-root": {
                    color: "inherit"
                },
                "& .MuiDivider-root": {
                    backgroundColor: "currentColor",
                    opacity: 0.3
                },
                "& .MuiListItemText-primary": {
                    fontSize: 13
                },
                "& .MuiListItemText-secondary": {
                    fontSize: 11,
                    color: '#6591B5'
                },
                "& .MuiListItem-root": {
                    "&$selected": {
                        backgroundColor: "#29303D",
                        color: "#ffffff",
                        "& .MuiListItemIcon-root": {
                            color: "#ffffff"
                        }
                    },
                    "& a:hover": {
                        backgroundColor: "#29303D",
                        color: "#ffffff",
                        "& .MuiListItemIcon-root": {
                            color: "#ffffff"
                        }
                    }
                },
            }
        }
    }
};

const theme = createTheme({
    typography: {
        fontSize: 13
    },
    shape: {
        borderRadius: 0,
    },
    palette: palette,
    components: components,
});

export default theme;