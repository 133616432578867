/**
 * Created by Robin on 02/03/2023.
 */

import React, {useContext, useState, useEffect} from "react";

import APIContext from "./../../contexts/APIContext.js";

import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

function DeleteConfirmDialog(props) {

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogContent>
                <Typography align="center">
                    Bent u zeker dat u deze lijn wilt verwijderen?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onClose()}>Annuleren</Button>
                <Button onClick={() => props.onDelete()} color="error">Verwijderen</Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeleteConfirmDialog;