/**
 * Created by Robin on 06/03/2023.
 */

import React, {useContext, useState, useEffect} from "react";

import APIContext from "./../../contexts/APIContext.js";

import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import PriceField from './../PriceField';

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import PaymentIcon from '@mui/icons-material/Payment';
import EuroIcon from '@mui/icons-material/Euro';
import TollIcon from '@mui/icons-material/Toll';
import UpdateIcon from '@mui/icons-material/Update';
import AddCardIcon from '@mui/icons-material/AddCard';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckIcon from '@mui/icons-material/Check';

function AddOrEditProductDialog(props) {
    const [no, setNo] = useState('');
    const [name, setName] = useState('');
    const [active, setActive] = useState(true);
    const [consignment, setConsignment] = useState(true);

    useEffect(() => {
        if (props.open && props.product) {
            setNo(props.product.internal_no);
            setName(props.product.name);
            setActive(props.product.active);
            setConsignment(props.product.consignment);
        } else {
            setNo('');
            setName('');
            setActive(true);
            setConsignment(false);
        }
    }, [props.open, props.product]);

    const handleProductSave = () => {
        if (props.product) {
            onProductUpdate();
        } else {
            onProductCreate();
        }

        if (typeof props.onClose === 'function') {
            props.onClose();
        }
    };

    const onProductCreate = () => {
        if (typeof props.onProductCreate === 'function') {
            props.onProductCreate(no, name, active, consignment)
        }
    };

    const onProductUpdate = () => {
        if (typeof props.onProductUpdate === 'function') {
            props.onProductUpdate(no, name, active, consignment)
        }
    };

    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth={true} maxWidth={'xs'}>
            <DialogContent style={{height: '100%', display: 'flex'}}>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <TextField label="No" value={no} onChange={(e) => {setNo(e.target.value)}} disabled={!!props.product} fullWidth/>
                    </Grid>
                    <Grid item>
                        <TextField label="Naam" value={name} onChange={(e) => {setName(e.target.value)}} fullWidth/>
                    </Grid>
                    <Grid item>
                        <FormControlLabel control={<Checkbox checked={active} onChange={() => setActive(!active)} />} label="Actief" />
                    </Grid>
                    <Grid item>
                        <FormControlLabel control={<Checkbox checked={consignment} onChange={() => setConsignment(!consignment)} />} label="Consignatie" />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onClose()}>Annuleren</Button>
                <Button variant="contained" onClick={handleProductSave}>Opslaan</Button>
            </DialogActions>
        </Dialog>
    );
}

export default AddOrEditProductDialog;