/**
 * Created by Robin on 06/03/2023.
 */

import React, {useContext, useState, useEffect} from "react";

import logo from '../../img/logo.jpg';

const RECEIPT_WIDTH_IN_CM = 7.8;

function PrintDailyTotals(props) {
    let body = document.body;
    let html = document.documentElement;


    const formatValue = (value) => {
        return new Intl.NumberFormat('fr-BE', { style: 'decimal', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
    };

    const paymentTotals = () => {
        let sum = 0.0;
        for (let v of Object.values(props.data.summary.payments)) {
            sum += v;
        }

        return sum;
    };

    const productCount = (receipt) => {
        let count = 0;
        for (let line of receipt.receipt_lines) {
            count += line.qty;
        }

        if (count === 1) {
            return "1 Artikel";
        } else {
            return count.toString() + " Artikelen";
        }
    };

    const calculateChange = (receipt) => {
        let change = parseFloat(receipt.total_rounded);

        for (let payment of receipt.payments) {
            change = change - parseFloat(payment.amount);
        }

        return Math.abs(change);
    };

    const renderReceipt = (receipt) => {
        return (
            <React.Fragment key={receipt.no}>
                <hr />
                <div style={{marginTop: 20, marginBottom: 20}}>
                    <p style={{padding: 0, margin: 0}}>{new Date(receipt.closingDate).toLocaleString('nl-BE')}</p>
                    <p style={{padding: 0, margin: 0}}>{receipt.no}</p>
                </div>
                <div>
                <table style={{width: '100%'}}>
                <tbody>
                {
                    receipt.receipt_lines.map((line) => {
                    return (
                        <React.Fragment key={line.id}>
                            <tr>
                                <td>
                                    {line.qty} x {line.product_group.name}
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td style={{paddingLeft: 15}}>
                                    {line.productGroupInternalNo} {line.discount_percentage !== 0 ? ('(KORTING ' + line.discount_percentage + '%)') : ''}
                                </td>
                                <td align="right">
                                    {'€' + formatValue(line.line_total)}
                                </td>
                            </tr>
                        </React.Fragment>
                    );
                })
            }
                </tbody>
                </table>
                </div>
                <div style={{marginTop: 10, marginBottom: 10}}>
                    <p>{productCount(receipt)}</p>
                </div>
                <div>
                    <table style={{width: '100%'}}>
                        <tbody>
                        {receipt.discount_percentage !== 0 &&
                        <React.Fragment>
                            <tr>
                                <td style={{fontSize: 18}}>Totaal zonder korting</td>
                                <td style={{fontSize: 18}}
                                    align="right">{'€' + formatValue(receipt.total_without_discount)}</td>
                            </tr>
                            <tr>
                                <td>Korting</td>
                                <td align="right">{receipt.discount_percentage + '%'}</td>
                            </tr>
                            <tr>
                                <td style={{fontSize: 18}}>Totaal</td>
                                <td style={{fontSize: 18}}
                                    align="right">{'€' + formatValue(receipt.total_rounded)}</td>
                            </tr>
                        </React.Fragment>
                        }
                        {receipt.discount_percentage === 0 &&
                        <React.Fragment>
                            <tr>
                                <td style={{fontSize: 18}}>Totaal</td>
                                <td style={{fontSize: 18}}
                                    align="right">{'€' + formatValue(receipt.total_rounded)}</td>
                            </tr>
                        </React.Fragment>
                        }
                        </tbody>
                    </table>
                </div>
                <div>
                    <table style={{width: '100%'}}>
                        <tbody>
                        {receipt.payments.map((payment) => {
                            return (
                                <tr key={payment.id}>
                                    <td>{payment.payment_method}</td>
                                    <td align="right">{'€' + formatValue(payment.amount)}</td>
                                </tr>
                            )
                        })}
                        <tr>
                            <td />
                            <td />
                        </tr>
                        <tr>
                        <td>Wisselgeld</td>
                        <td align="right">{'€' + formatValue(calculateChange(receipt))}</td>
                    </tr>
                </tbody>
                </table>
                </div>
            </React.Fragment>
        )
    };

    if (props.data) {
        console.log(props.data);

        return (
            <div style={{width: RECEIPT_WIDTH_IN_CM + 'cm'}} ref={props.contentRef}>
                {props.printReceipts !== true &&
                    <React.Fragment>
                        <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                            <img src={logo} style={{width: '150px'}}/>
                        </div>
                        <div style={{marginTop: 20, marginBottom: 20}}>
                            <p style={{padding: 0, margin: 0}}><b>DAGTOTALEN - {props.data.summary.shopName}</b></p>
                            <p style={{padding: 0, margin: 0}}>{new Date(props.date).toLocaleString('nl-BE', {year: 'numeric', month: 'long', day: 'numeric',})}</p>
                        </div>
                        <div>
                            <table>
                                <tbody>
                                    {
                                        Object.keys(props.data.summary.payments).map((paymentMethod) => {
                                            return (
                                                <tr key={paymentMethod}>
                                                    <td style={{width: '100%'}}>
                                                        {paymentMethod}
                                                    </td>
                                                    <td>
                                                        {formatValue(props.data.summary.payments[paymentMethod])}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    <tr>
                                        <td style={{width: '100%'}}><b>Totaal</b></td>
                                        <td><b>{formatValue(paymentTotals())}</b></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </React.Fragment>
                }
                {props.printReceipts === true && props.data.receipts.map((receipt) => {
                    return renderReceipt(receipt);
                })}
                <style>{`
            @page {
                margin: 10px;
                size: 7.8cm 20cm;
            }
            `}</style>
            </div>
        );
    } else {
        return [];
    }
}

export default PrintDailyTotals;