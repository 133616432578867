/**
 * Created by Robin on 27/02/2023.
 */

import { useContext } from 'react';
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";

import LoginPage from './pages/Login'
import UserPage from './pages/User.js'
import ManagerPage from './pages/Manager.js'

import CashRegister from './pages/CashRegister.js';
import History from './pages/History.js';

import ProductManagement from './pages/ProductManagement.js';
import ProductMonthlyStats from './pages/ProductMonthlyStats.js';
import ManagerHistory from './pages/ManagerHistory.js';
import ManagerProductStats from './pages/ManagerProductStats.js';
import ReceiptFooterConfig from './pages/ReceiptFooterConfig.js';
import ManagerDashboard from './pages/ManagerDashboard.js';
import ManagerMonthStats from './pages/ManagerMonthStats.js';

import APIContext from './contexts/APIContext.js';

const userRouter = createBrowserRouter([
    {
        path: "/",
        element: <UserPage />,
        children: [
            {
                path: "/",
                element: (<Navigate to="/register/1" replace />)
            },
            {
                path: "/register/:cartNo",
                element: <CashRegister />
            },
            {
                path: "/history",
                element: <History />
            }
        ]
    }
]);

const managerRouter = createBrowserRouter([
    {
        path: "/",
        element: <ManagerPage />,
        children: [
            {
                path: "/",
                element: (<ManagerDashboard />)
            },
            {
                path: '/product',
                element: <ProductManagement />
            },
            {
                path: '/product/:productNo',
                element: <ProductMonthlyStats />
            },
            {
                path: '/history',
                element: <ManagerHistory />
            },
            {
                path: '/stats',
                element: <ManagerProductStats />
            },
            {
                path: '/month',
                element: <ManagerMonthStats />
            }
            ,
            {
                path: '/footer',
                element: <ReceiptFooterConfig />
            }
        ]
    }
]);

function AppRouter(props) {
    const API = useContext(APIContext);

    if (API.isLoggedIn()) {
        if (API.isPosUser()) {
            return <RouterProvider router={userRouter} />
        } else {
            return <RouterProvider router={managerRouter} />
        }
    } else {
        return (<LoginPage />)
    }
}

export default AppRouter