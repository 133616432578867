/**
 * Created by Robin on 28/02/2023.
 */

import React, {useContext, useState, useEffect, useRef} from "react";

import APIContext from "./../../contexts/APIContext.js";

import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

function ProductSearchDialog(props) {
    const API = useContext(APIContext);

    const [searchValue, setSearchValue] = useState("");
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);

    const [searchInputRef, setSearchInputRef] = useState(null);

    useEffect(() => {
        if (props.open) {
            API.searchProducts('').then((products) => {
                if (products) {
                    setProducts(products);
                }
            });
        }
    }, [props.open]);

    useEffect(() => {
        setFilteredProducts(getFilteredProducts(searchValue));
    }, [products, searchValue]);

    useEffect(() => {
        if (props.open && searchInputRef) {
            searchInputRef.focus();
        }
    }, [searchInputRef]);

    const refSearchCallback = (node) => {
        setSearchInputRef(node);
    };

    const searchKeyPress = (e) => {
        if (e.key === 'Enter') {
            if (filteredProducts.length > 0) {
                props.onChange(filteredProducts[0].internal_no);
                props.onClose();
                setSearchValue("");
            }
        }
    };

    const getFilteredProducts = (filter) => {
        let filtered = [];

        filter = filter.toUpperCase();

        for (let product of products) {
            if (product.internal_no.toUpperCase().includes(filter) || product.name.toUpperCase().includes(filter)) {
                filtered.push(product);
            }
        }

        return filtered;
    };

    const renderProducts = () => {
        let items = [];

        for (let product of filteredProducts) {
            items.push(
                <Grid item xs={6} md={4} lg={3} key={product.internal_no}>
                    <Box style={{border: '1px solid #aaa', padding: 10, cursor: 'pointer'}} onClick={() => {props.onChange(product.internal_no); props.onClose(); setSearchValue("")}}>
                        <Typography align="center"><b>{product.name}</b></Typography>
                        <Typography align="center"><b>{product.internal_no}</b></Typography>
                    </Box>
                </Grid>
            )
        }

        return items;
    };

    return (
        <Dialog open={props.open} fullWidth={true} maxWidth={false} PaperProps={{style: { maxHeight: '90vh', minHeight: '90vh'}}} onClose={props.onClose}>
            <DialogContent>
                <TextField
                    label="Zoeken"
                    value={searchValue}
                    onChange={(ev) => {setSearchValue(ev.target.value)}}
                    inputRef={refSearchCallback}
                    InputProps={{
                        endAdornment: (
                            <IconButton onClick={() => setSearchValue("")}>
                                {searchValue ? <ClearIcon /> : <SearchIcon />}
                            </IconButton>
                        ),
                    }}
                    onKeyDown={searchKeyPress}
                    fullWidth
                />
                <Box style={{marginTop: 40}}>
                    <Grid container spacing={2}>
                        {renderProducts()}
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onClose()}>Annuleren</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ProductSearchDialog;