/**
 * Created by Robin on 06/03/2023.
 */

import React, {useContext, useState, useEffect, useRef} from "react";

import { useReactToPrint } from 'react-to-print';

import {Link, Outlet, useParams} from "react-router-dom";

import { useTheme } from '@mui/material/styles';

import APIContext from "./../contexts/APIContext.js";

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import ProductLine from './../components/ProductLine';
import RegisterDrawerContent from './../components/RegisterDrawerContent';
import CheckoutDialog from './../components/CheckoutDialog';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import AddOrEditProductDialog from './../components/ProductLine/AddOrEditProductDialog.js';

function ProductManagement(props) {
    const API = useContext(APIContext);
    const [products, setProducts] = useState([]);
    const [filter, setFilter] = useState("");
    const [open, setOpen] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);
    const [successAlert, setSuccessAlert] = useState(null);

    useEffect(() => {
        API.getAllProducts().then((products) => {
           if (products) {
               setProducts(products);
           }
        });
    }, []);

    const updateProduct = async (no, name, active, consignment) => {
        let res = await API.updateProduct(no, name, active, consignment);

        if (res) {
            API.getAllProducts().then((products) => {
                if (products) {
                    setProducts(products);
                }
            });
            setSuccessAlert("Product succesvol bijgewerkt");
        } else {
            setErrorAlert("Fout bij het bewerken van product");
        }
    };

    const createProduct = async (no, name, active, consignment) => {
        let res = await API.createProduct(no, name, active, consignment);

        if (res) {
            API.getAllProducts().then((products) => {
                if (products) {
                    setProducts(products);
                }
            });
            setSuccessAlert("Product succesvol toegevoegd");
        } else {
            setErrorAlert("Fout bij het toevoegen van product");
        }
    };

    return (
        <Card>
            <CardHeader
                title="Producten"
                action={
                    <Grid container justifyContent="center" alignItems="center" spacing={2}>
                        <Grid item>
                            <Button variant="contained" startIcon={<AddIcon />} onClick={() => setOpen(true)}>Nieuw product</Button>
                            <AddOrEditProductDialog
                                open={open}
                                onClose={() => setOpen(false)}
                                onProductCreate={createProduct}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                size="small"
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                            <SearchIcon />
                                    )}}
                            />
                        </Grid>
                    </Grid>
                }
            />
            <CardContent>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>No.</TableCell>
                            <TableCell>Naam</TableCell>
                            <TableCell>Actief</TableCell>
                            <TableCell>Consignatie</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            products.map((product) => {
                                if (product.internal_no.toUpperCase().includes(filter.toUpperCase()) || product.name.toUpperCase().includes(filter.toUpperCase())) {
                                    return (
                                        <ProductLine
                                            product={product}
                                            key={product.internal_no}
                                            onProductUpdate={updateProduct}
                                        />
                                    );
                                } else {
                                    return [];
                                }
                            })
                        }
                    </TableBody>
                </Table>
            </CardContent>
            <Snackbar
                open={!!errorAlert}
                autoHideDuration={6000}
                onClose={() => (setErrorAlert(null))}
            >
                <Alert
                    severity="error"
                    sx={{width: '100%'}}
                >
                    {errorAlert}
                </Alert>
            </Snackbar>
            <Snackbar
                open={!!successAlert}
                autoHideDuration={6000}
                onClose={() => (setSuccessAlert(null))}
            >
                <Alert
                    severity="success"
                    sx={{width: '100%'}}
                >
                    {successAlert}
                </Alert>
            </Snackbar>
        </Card>
    );
}

export default ProductManagement;