/**
 * Created by Robin on 03/03/2023.
 */

import React, {useContext, useState, useEffect, useRef} from "react";

import { useTheme } from '@mui/material/styles';

import moment from 'moment';
import 'moment/locale/nl';

import APIContext from "./../contexts/APIContext.js";

import { useReactToPrint } from 'react-to-print';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import Toolbar from '@mui/material/Toolbar';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import HistoryLine from './../components/HistoryLine';
import RegisterDrawerContent from './../components/RegisterDrawerContent';
import CheckoutDialog from './../components/CheckoutDialog';

import PaymentIcon from '@mui/icons-material/Payment';
import PrintIcon from '@mui/icons-material/Print';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

import PrintDailyTotals from './../components/PrintDailyTotals';

function History(props) {
    const theme = useTheme();
    const API = useContext(APIContext);

    const [receipts, setReceipts] = useState([]);

    const [data, setData] = useState(null);

    const printContentRef = useRef(null);
    const printButtonRef = useRef(null);

    const [printAllReceipts, setPrintAllReceipts] = useState(true);

    useEffect(() => {
        API.getShopDailyTotals(0).then((data) => {
            setData(data);
        });
    }, []);

    const handlePrint = useReactToPrint({
        content: () => printContentRef.current,
    });

    useEffect(() => {
        API.getReceiptsToday().then((receipts) => {
            setReceipts(receipts);
        });
    },[]);

    const renderLines = () => {
        let items = [];

        for (let receipt of receipts) {
            items.push(
                <HistoryLine receipt={receipt} key={receipt.id} />
            );
        }

        return items;
    };

    return (
        <Card>
            <CardContent>
                <CardHeader title="Kasticketten overzicht" action={
                    <React.Fragment>
                        <Button onClick={() => {setPrintAllReceipts(false); setTimeout(() => printButtonRef.current.click(), 500); }}><PrintIcon /></Button>
                        <Button onClick={() => {setPrintAllReceipts(true); setTimeout(() => printButtonRef.current.click(), 500) }}><ReceiptLongIcon /></Button>
                    </React.Fragment>
                }/>
                <Box style={{display: 'none'}}>
                    <button onClick={handlePrint} ref={printButtonRef}>HIDDEN PRINT</button>
                    <PrintDailyTotals data={data} contentRef={printContentRef} printReceipts={printAllReceipts} date={moment().format('YYYY-MM-DD')} />
                </Box>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>No</TableCell>
                            <TableCell>Datum</TableCell>
                            <TableCell align="right">Totaal bedrag</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderLines()}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    );
}

export default History