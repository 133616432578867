/**
 * Created by Robin on 07/03/2023.
 */

import React, {useContext, useState, useEffect} from "react";

import APIContext from "./../../contexts/APIContext.js";

import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

function ShopSelector(props) {
    const API = useContext(APIContext);
    const [shops, setShops] = useState([]);

    useEffect(() => {
        API.getShops().then((shops) => {
            if (shops) {
                setShops(shops);
            }
        })
    }, []);

    const renderOptions = () => {
        let items = [];

        for (let shop of shops) {
            items.push(
                <MenuItem value={shop.id} key={shop.id}>{shop.name}</MenuItem>
            );
        }

        return items;
    };

    return (
        <FormControl fullWidth>
            <InputLabel>{props.label ? props.label : "Winkel"}</InputLabel>
            <Select
                value={props.value}
                onChange={props.onChange}
                label={props.label ? props.label : "Winkel"}
            >
                <MenuItem value={""}><i>Alle winkels</i></MenuItem>
                {renderOptions()}
            </Select>
        </FormControl>
    );
}

export default ShopSelector;