/**
 * Created by Robin on 13/03/2023.
 */

import React, {useContext, useState, useEffect, useRef} from "react";
import {Link, Outlet, useParams} from "react-router-dom";

import { useReactToPrint } from 'react-to-print';

import APIContext from "./../../contexts/APIContext.js";

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import PrintIcon from '@mui/icons-material/Print';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

import PrintDailyTotals from './../PrintDailyTotals';

function DashboardShopCard(props) {
    const API = useContext(APIContext);
    const [data, setData] = useState(null);

    const [printReceipt, setPrintReceipt] = useState(null);
    const printContentRef = useRef(null);
    const printButtonRef = useRef(null);

    const [printAllReceipts, setPrintAllReceipts] = useState(true);

    const handlePrint = useReactToPrint({
        content: () => printContentRef.current,
    });

    useEffect(() => {
        API.getShopDailyTotalsForDate(props.data.shopId, props.date.format('YYYY-MM-DD')).then((data) => {
           setData(data);
        });
    }, [props.data, props.date]);


    const formatValue = (value) => {
        return new Intl.NumberFormat('fr-BE', { style: 'decimal', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
    };

    console.log(props);

    return (
        <Card>
            <CardHeader title={props.name} action={
                <React.Fragment>
                    <Button onClick={() => {setPrintAllReceipts(false); setTimeout(() => printButtonRef.current.click(), 500); }}><PrintIcon /></Button>
                    <Button onClick={() => {setPrintAllReceipts(true); setTimeout(() => printButtonRef.current.click(), 500) }}><ReceiptLongIcon /></Button>
                </React.Fragment>
            }/>
            <Card>
                <Box style={{display: 'none'}}>
                    <button onClick={handlePrint} ref={printButtonRef}>HIDDEN PRINT</button>
                    <PrintDailyTotals data={data} contentRef={printContentRef} printReceipts={printAllReceipts} date={props.date.format('YYYY-MM-DD')} />
                </Box>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell variant="head">Kasticket aantal</TableCell>
                            <TableCell align="right">{props.data.receipt_count}</TableCell>
                        </TableRow>
                        {Object.keys(props.data.payments).map((paymentType) => {
                            return (
                                <TableRow key={paymentType}>
                                    <TableCell variant="head">{paymentType.slice(0,1).toUpperCase() + paymentType.slice(1).toLowerCase()}</TableCell>
                                    <TableCell align="right">€ {formatValue(props.data.payments[paymentType])}</TableCell>
                                </TableRow>
                            );
                        })}
                        <TableRow>
                            <TableCell variant="head">Totaal</TableCell>
                            <TableCell variant="head" align="right">€ {formatValue(props.data.total)}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Card>
        </Card>
    );
}

export default DashboardShopCard;