/**
 * Created by Robin on 27/02/2023.
 */

import React, {useContext, useState, useEffect, useRef} from "react";
import Cookies from "js-cookie";

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import APIContext from "./../contexts/APIContext.js";


export default function SignIn(props) {

    const API = useContext(APIContext);

    const buttonRef = useRef(null);

    /*
     enum authState {
     'AWAITING_INPUT',
     'AWAITING_REQUEST',
     'INVALID',
     'SUCCESS'
     }
     */
    const [authState, setAuthState] = useState('AWAITING_INPUT');
    const [rememberMe, setRememberMe] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    /*
    useEffect(() => {
        let cookieEmail = Cookies.get('email');
        let cookiePassword = Cookies.get('password');
        let cookieRememberMe = Cookies.get('rememberme') !== undefined;

        if (cookieEmail !== undefined && cookiePassword !== undefined) {

            //Cookie authentication
            if (authState === 'AWAITING_INPUT') {
                setAuthState('AWAITING_REQUEST');
                API.authenticate(cookieRememberMe, cookieEmail, cookiePassword).then((result) => {
                    if (result === true) {
                        setAuthState('SUCCESS');
                    }
                });
            }
        }
    }, [API]);
    */

    const passwordKeyPress = (e) => {
        if (e.key === 'Enter') {
            doAuth();
        }
    };

    const doAuth = async () => {
        setAuthState('AWAITING_REQUEST');
        let success = await API.authenticate(username, password);

        if (!success) {
            setAuthState('INVALID');

            setTimeout(() => {
                setAuthState('AWAITING_INPUT');
            }, 1500);
        } else {
            setAuthState('SUCCESS');
        }

    };

    const LoginButton = function(props) {
        let text = "Aanmelden";
        let color = undefined;
        let loading = false;

        if (authState === 'INVALID') {
            text = "Ongeldige aanmeldgegevens";
            color = "error";
        } else if (authState === 'AWAITING_REQUEST') {
            text = "";
            loading = true;
        }

        return (<LoadingButton
            loading={loading}
            type="submit"
            fullWidth
            variant="contained"
            onClick={() => doAuth()}
            sx={{ mt: 3, mb: 2 }}
            color={color}
            ref={buttonRef}
        >
            {text}
        </LoadingButton>);
    };


    return(
        <Grid container alignItems="center" justifyContent="center" style={{height: '100%'}}>
            <Paper elevation={10} style={{
                padding: 20,
                width: 280
            }}>
                <Grid align='center'>
                    <Avatar style={{backgroundColor: '#1bbd7e'}}><LockOutlinedIcon/></Avatar>
                    <h2>Aanmelden</h2>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField label='Gebruikersnaam' placeholder='Gebruikersnaam' value={username} variant="outlined" fullWidth required onChange={(event) => setUsername(event.target.value)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label='Wachtwoord' placeholder='Wachtwoord' value={password} type='password' variant="outlined" fullWidth required onChange={(event) => setPassword(event.target.value)} onKeyDown={passwordKeyPress}/>
                    </Grid>
                    <Grid item xs={12}>
                        <LoginButton />
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}