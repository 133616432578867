
import React, {useContext, useState, useEffect} from "react";

import APIContext from "./../../contexts/APIContext.js";

import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import PriceField from './../PriceField';

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import PaymentIcon from '@mui/icons-material/Payment';
import EuroIcon from '@mui/icons-material/Euro';
import TollIcon from '@mui/icons-material/Toll';
import UpdateIcon from '@mui/icons-material/Update';
import AddCardIcon from '@mui/icons-material/AddCard';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckIcon from '@mui/icons-material/Check';

import PrintReceipt from './../PrintReceipt';

function CheckoutDialog(props) {
    const API = useContext(APIContext);
    const [paymentAmount, setPaymentAmount] = useState(0.0);
    const [paymentMethod, setPaymentMethod] = useState(null);

    const [amountInputRef, setAmountInputRef] = useState(null);

    useEffect(() => {
        if (props.open && amountInputRef) {
            amountInputRef.focus();
        }
    }, [amountInputRef]);

    useEffect(() => {
        if (props.receipt && props.open) {
            setPaymentAmount(props.receipt.outstandingBalance);
        }

    }, [props.receipt, props.open]);

    useEffect(() => {
        if (props.open && amountInputRef) {
            amountInputRef.select();
        }
    }, [paymentAmount, paymentMethod]);

    const amountInputRefCallback = (node) => {
        setAmountInputRef(node);
    };

    const formatValue = (value) => {
        return new Intl.NumberFormat('fr-BE', { style: 'decimal', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
    };

    const hasError = () => {
        if (props.receipt.payment_method === 'CASH') {
            if (paymentAmount < props.receipt.total_rounded) {
                return true;
            }
        }

        return false;
    };

    const finalize = () => {
        if (typeof props.onFinalize === 'function') {
            props.onFinalize();
        }

        setPaymentAmount(0.0);
        setPaymentMethod(null);
    };

    const addPayment = async (value) => {
        if (value) {
            if (typeof props.onAddPayment === 'function') {
                await props.onAddPayment(paymentMethod, value);
            }
        } else {
            if (typeof props.onAddPayment === 'function') {
                await props.onAddPayment(paymentMethod, paymentAmount);
            }
        }

        setPaymentAmount(0.0);
        setPaymentMethod(null);
    };

    const removePayment = async (paymentId) => {
        if (typeof props.onRemovePayment === 'function') {
            props.onRemovePayment(paymentId);
        }
    };

    const calculateChange = () => {
        let change = parseFloat(props.receipt.total_rounded);

        for (let payment of props.receipt.payments) {
            change = change - parseFloat(payment.amount);
        }

        return Math.abs(change);
    };

    const renderPayments = () => {
        if (props.receipt.payments.length === 0) {
            return (
                <Typography align="center"><i>Geen betalingen</i></Typography>
            )
        } else {
            return (
                <Table>
                    <TableBody>
                        {
                            props.receipt.payments.map((payment) => {
                                return (
                                    <TableRow key={payment.id}>
                                        <TableCell variant="head">{payment.payment_method}</TableCell>
                                        <TableCell align="right">{'€' + formatValue(payment.amount)}</TableCell>
                                        <TableCell align="right" onClick={() => {removePayment(payment.id)}}><IconButton><DeleteForeverIcon /></IconButton></TableCell>
                                    </TableRow>
                                );
                            })
                        }
                    </TableBody>
                </Table>
            )
        }
    };

    if (props.receipt) {
        return (
            <Dialog open={props.open} fullWidth={true} maxWidth={false}
                    PaperProps={{style: {maxHeight: '90vh', minHeight: '90vh', height: '90vh'}}} onClose={props.onClose}>
                <Grid container style={{height: '100%'}}>
                    <Grid item xs={3} style={{backgroundColor: '#F1F5F9', height: '100%'}}>
                        <DialogContent style={{height: '100%'}}>
                            <Grid container direction="column" justifyContent="space-between" style={{height: '100%'}}>
                                <Grid item>
                                    <Typography variant="h5" align="center">
                                        Totaal bedrag
                                    </Typography>
                                    <Typography variant="h4" align="center">
                                        {'€' + formatValue(props.receipt.total_rounded)}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h5" align="center">
                                        Betalingen
                                    </Typography>
                                    {renderPayments()}
                                </Grid>
                                {parseFloat(props.receipt.outstandingBalance) !== 0.0 &&
                                    <Grid item>
                                        <Typography variant="h5" align="center">
                                            Openstaand saldo
                                        </Typography>
                                        <Typography variant="h4" align="center" style={{color: 'red'}}>
                                            {'€' + formatValue(props.receipt.outstandingBalance)}
                                        </Typography>
                                    </Grid>
                                }
                                {parseFloat(props.receipt.outstandingBalance) === 0.0 &&
                                <Grid item>
                                    <Typography variant="h5" align="center">
                                        Wisselgeld
                                    </Typography>
                                    <Typography variant="h4" align="center">
                                        {'€' + formatValue(calculateChange())}
                                    </Typography>
                                </Grid>
                                }
                            </Grid>
                        </DialogContent>
                    </Grid>
                    <Grid item xs={9} style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
                        <DialogContent style={{flex: 1}}>
                            {parseFloat(props.receipt.outstandingBalance) !== 0.0 &&
                            <Grid container direction="column" alignItems="center" justifyContent="space-between"
                                  style={{height: '100%'}}>
                                <Grid item>
                                    <ToggleButtonGroup exclusive value={paymentMethod} onChange={(_, value) => {
                                        if (value !== null) {
                                            setPaymentMethod(value);
                                        }
                                    }}>
                                        <ToggleButton value="CASH">
                                            <Grid container direction="column" alignItems="center"
                                                  justifyContent="center"
                                                  style={{width: 150, height: 100}}>
                                                <Grid item>
                                                    <EuroIcon fontSize="large"/>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="h6">Cash</Typography>
                                                </Grid>
                                            </Grid>
                                        </ToggleButton>
                                        <ToggleButton value="BANCONTACT">
                                            <Grid container direction="column" alignItems="center"
                                                  justifyContent="center"
                                                  style={{width: 150, height: 100}}>
                                                <Grid item>
                                                    <PaymentIcon fontSize="large"/>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="h6">Bancontact</Typography>
                                                </Grid>
                                            </Grid>
                                        </ToggleButton>
                                        <ToggleButton value="CREDITCARD">
                                            <Grid container direction="column" alignItems="center"
                                                  justifyContent="center"
                                                  style={{width: 150, height: 100}}>
                                                <Grid item>
                                                    <TollIcon fontSize="large"/>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="h6">Kredietkaart</Typography>
                                                </Grid>
                                            </Grid>
                                        </ToggleButton>
                                        <ToggleButton value="LATER">
                                            <Grid container direction="column" alignItems="center"
                                                  justifyContent="center"
                                                  style={{width: 150, height: 100}}>
                                                <Grid item>
                                                    <UpdateIcon fontSize="large"/>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="h6">Later betalen</Typography>
                                                </Grid>
                                            </Grid>
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h5" align="center">Betaald bedrag</Typography>
                                    <PriceField
                                        value={paymentAmount}
                                        onChange={(val) => setPaymentAmount(val)}
                                        onEnter={addPayment}
                                        inputRef={amountInputRefCallback}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" startIcon={<AddCardIcon />}
                                            disabled={parseFloat(paymentAmount) === 0.0 || paymentMethod === null}
                                            onClick={() => {addPayment(paymentAmount)}}>Betaling toevoegen
                                    </Button>
                                </Grid>
                            </Grid>
                            }
                            {parseFloat(props.receipt.outstandingBalance) === 0.0 &&
                                <Grid container direction="column" justifyContent="center" alignItems="center" style={{height: '100%'}} spacing={4}>
                                    <Grid item>
                                        <Typography align="center">
                                            <CheckIcon fontSize="large" color="success" style={{height: '128px', width: '128px'}} />
                                        </Typography>
                                        <Typography variant="h5" align="center">Saldo voldaan!</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" startIcon={<ReceiptLongIcon />} onClick={finalize}>Verwerken en afdrukken</Button>
                                    </Grid>
                                </Grid>
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => props.onClose()}>Annuleren</Button>
                        </DialogActions>
                    </Grid>
                </Grid>
            </Dialog>
        );
    } else {
        return [];
    }
}

export default CheckoutDialog