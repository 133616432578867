/**
 * Created by Robin on 13/03/2023.
 */

import React, {useContext, useState, useEffect} from "react";

import moment from 'moment';
import 'moment/locale/nl';

import { useTheme } from '@mui/material/styles';

import APIContext from "./../../contexts/APIContext.js";

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';


import PaymentIcon from '@mui/icons-material/Payment';
import EuroIcon from '@mui/icons-material/Euro';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDownIcon from '@mui/icons-material/ArrowDropDown';

function MonthLine(props) {
    const [open, setOpen] = useState(false);

    let month = props.month;
    let shopNames = props.shopNames;
    let data = props.data;

    const borderRightStyle = {
        borderRight: '1px solid #ccc'
    };

    const formatValue = (value) => {
        return new Intl.NumberFormat('fr-BE', { style: 'decimal', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
    };

    const safeGetQty = (shopName, product_no) => {
        if (product_no in data.shops[shopName].products) {
            return data.shops[shopName].products[product_no].qty;
        } else {
            return 0;
        }
    };

    const safeGetAmount = (shopName, product_no) => {
        if (product_no in data.shops[shopName].products) {
            return data.shops[shopName].products[product_no].amount;
        } else {
            return 0.0;
        }
    };

    const renderProductLines = () => {
        let items = [];

        let products = {};
        for (let shopName of shopNames) {
            for (let product of Object.keys(data.shops[shopName].products)) {
                products[product] = data.shops[shopName].products[product].name
            }
        }

        for (let product_no of Object.keys(products)) {
            let product_amount = 0.0;
            let product_qty = 0;
            items.push(
                <TableRow key={product_no}>
                    <TableCell style={borderRightStyle}>
                        <i>{product_no} - {products[product_no]}</i>
                    </TableCell>
                    {shopNames.map((shopName) => {
                        let amount = safeGetAmount(shopName, product_no);
                        let qty = safeGetQty(shopName, product_no);

                        product_amount += amount;
                        product_qty += qty;

                        return (
                            <React.Fragment key={shopName}>
                                <TableCell style={borderRightStyle} align="right"><i>{qty}</i></TableCell>
                                <TableCell style={borderRightStyle} align="right"><i>€ {formatValue(amount)}</i></TableCell>
                            </React.Fragment>
                        )
                    })}
                    <TableCell style={borderRightStyle} variant="head" align="right"><i>{product_qty}</i></TableCell>
                    <TableCell variant="head" align="right"><i>€ {formatValue(product_amount)}</i></TableCell>
                </TableRow>
            );
        }
        return items;
    };

    return (
        <React.Fragment>
            <TableRow>
                <TableCell style={{...borderRightStyle, cursor: 'pointer'}} variant="head" onClick={() => {setOpen(!open)}}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}>
                        {open ? <ArrowDownIcon size="large"/> : <ArrowRightIcon  size="large"/>}
                        <span>{moment(month + '-01').format('MMMM YYYY')}</span>
                    </div>
                </TableCell>
                {shopNames.map((shopName) => {
                    return (
                        <React.Fragment key={shopName}>
                            <TableCell style={borderRightStyle} align="right">{data.shops[shopName].total_qty}</TableCell>
                            <TableCell style={borderRightStyle} align="right">€ {formatValue(data.shops[shopName].total_amount)}</TableCell>
                        </React.Fragment>
                    )
                })}
                <TableCell style={borderRightStyle} variant="head" align="right">{data.total_qty}</TableCell>
                <TableCell variant="head" align="right">€ {formatValue(data.total_amount)}</TableCell>
            </TableRow>
            {open && renderProductLines()}
        </React.Fragment>
    );
}

export default MonthLine;