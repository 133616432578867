/**
 * Created by Robin on 06/03/2023.
 */

import React, {useContext, useState, useEffect, useRef} from "react";

import { useReactToPrint } from 'react-to-print';

import {Link, Outlet, useParams} from "react-router-dom";

import { useTheme } from '@mui/material/styles';

import APIContext from "./../../contexts/APIContext.js";

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import AssessmentIcon from '@mui/icons-material/Assessment';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import AddOrEditProductDialog from './AddOrEditProductDialog.js';

function ProductLine(props) {
    const [open, setOpen] = useState(false);

    return (
        <TableRow key={props.product.internal_no}>
            <TableCell><Button onClick={() => setOpen(true)}>{props.product.internal_no}</Button></TableCell>
            <TableCell>{props.product.name}</TableCell>
            <TableCell>{props.product.active ? <CheckIcon color="success"/> :
                <CloseIcon color="error"/> }</TableCell>
            <AddOrEditProductDialog
                open={open}
                onClose={() => setOpen(false)}
                product={props.product}
                onProductUpdate={props.onProductUpdate}
            />
            <TableCell>{props.product.consignment ? <CheckIcon color="success"/> : []}</TableCell>
            <TableCell><IconButton component={Link} to={"/product/" + props.product.internal_no} ><AssessmentIcon /></IconButton></TableCell>
        </TableRow>
    )
}

export default ProductLine;