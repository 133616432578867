/**
 * Created by Robin on 13/03/2023.
 */

import React, {useContext, useState, useEffect} from "react";

import moment from 'moment';
import 'moment/locale/nl';

import { useTheme } from '@mui/material/styles';

import APIContext from "./../../contexts/APIContext.js";

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import MonthLine from './MonthLine.js';


import PaymentIcon from '@mui/icons-material/Payment';
import EuroIcon from '@mui/icons-material/Euro';

function MonthOverview(props) {
    const shopNames = () => {
        return Object.keys(props.data[Object.keys(props.data)[0]].shops);
    };

    const borderRightStyle = {
        borderRight: '1px solid #ccc'
    };

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell style={borderRightStyle}>Maand</TableCell>
                    {shopNames().map((shopName) => {
                        return (
                            <TableCell style={borderRightStyle} key={shopName} colSpan={2} align="center">{shopName}</TableCell>
                        )
                    })}
                    <TableCell colSpan={2} align="center">Totaal</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={borderRightStyle} />
                    {shopNames().map((shopName) => {
                        return (
                            <React.Fragment key={shopName}>
                                <TableCell style={borderRightStyle} align="right">Aantal</TableCell>
                                <TableCell style={borderRightStyle} align="right">Prijs</TableCell>
                            </React.Fragment>
                        )
                    })}
                    <TableCell style={borderRightStyle} align="right">Aantal</TableCell>
                    <TableCell align="right">Prijs</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {Object.keys(props.data).sort().map((month) => {
                    let data = props.data[month];

                    return (
                        <MonthLine month={month} data={data} shopNames={shopNames()} />
                    )
                    /*
                    return (
                        <TableRow key={month}>
                            <TableCell style={borderRightStyle} variant="head">{moment(month + '-01').format('MMMM YYYY')}</TableCell>
                            {shopNames().map((shopName) => {
                                return (
                                    <React.Fragment key={shopName}>
                                        <TableCell style={borderRightStyle} align="right">{data.shops[shopName].total_qty}</TableCell>
                                        <TableCell style={borderRightStyle} align="right">€ {formatValue(data.shops[shopName].total_amount)}</TableCell>
                                    </React.Fragment>
                                )
                            })}
                            <TableCell style={borderRightStyle} variant="head" align="right">{data.total_qty}</TableCell>
                            <TableCell variant="head" align="right">€ {formatValue(data.total_amount)}</TableCell>
                        </TableRow>
                    );
                    */
                })}
            </TableBody>
        </Table>
    )
}

export default MonthOverview;