/**
 * Created by Robin on 28/02/2023.
 */

import React, {useContext, useState, useEffect, useRef} from "react";

import * as currency from 'currency.js';

import APIContext from "./../../contexts/APIContext.js";
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

function PriceField(props) {
    const [value, setValue] = useState(1337.1337);
    const [inputRef, setInputRef] = useState(null);

    useEffect(() => {
        safeSetValue(props.value, true);
    }, [props.value, inputRef]);

    const formatValue = (value) => {
        return new Intl.NumberFormat('fr-BE', { style: 'decimal', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
    };

    const inputRefCallback = (node) => {
        setInputRef(node);

        if (typeof props.inputRef === 'function') {
            props.inputRef(node);
        }
    };

    const safeSetValue = (value, skipCallback = false) => {
        if (value || value === 0 || value === 0.0) {
            if (typeof value === 'number') {
                value = value.toString();
            }

            value = value.replace(' ', '');
            value = value.replace(',', '.');
            let cur = currency(value);

            if (inputRef) {
                inputRef.value = formatValue(cur.value);
            }

            setValue(cur.value);

            if (typeof props.onChange === 'function' && !skipCallback) {
                props.onChange(cur.value)
            }

            return cur.value;
        }
    };

    return (
        <TextField
            defaultValue={formatValue(0)}
            onBlur={(e) => safeSetValue(e.target.value)}
            inputRef={inputRefCallback}
            error={props.error}
            onFocus={event => {
                event.target.select();
            }}
            onKeyPress= {(e) => {
                if (e.key === 'Enter') {
                    const value = safeSetValue(e.target.value);
                    if (typeof props.onEnter === 'function') {
                        props.onEnter(value);
                    }
                }
            }}
            InputProps={{
                readOnly: props.readOnly,
                inputProps: {
                    style: {textAlign: 'right'}
                },
                startAdornment: <Typography>€</Typography>
            }}
        />
    );
}

export default PriceField;