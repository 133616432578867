/**
 * Created by Robin on 13/03/2023.
 */

import React, {useContext, useState, useEffect} from "react";
import {Link, Outlet, useParams} from "react-router-dom";

import APIContext from './../contexts/APIContext.js';

import moment from 'moment';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import DashboardShopCard from './../components/DashboardShopCard';

function ManagerDashboard(props) {
    const API = useContext(APIContext);
    const [shops, setShops] = useState([]);
    const [date, setDate] = useState(moment());

    useEffect(() => {
        API.getShopTotalsForDate(date.format('YYYY-MM-DD')).then((res) => {
            setShops(res);
        });
    }, [date]);

    return (
        <React.Fragment>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={12}>
                <Card>
                    <CardHeader title="Dashboard" action={
                        <DatePicker label="Datum" format="DD/MM/YYYY" value={date} onChange={(value) => setDate(value)}/>
                    }>
                    </CardHeader>
                </Card>
                </Grid>
                <Grid item>

                </Grid>
            </Grid>
            <Grid container spacing={4}>
                {Object.keys(shops).map((shopName) => {
                    return (
                        <Grid item xs={12} sm={6} key={shopName}>
                            <DashboardShopCard name={shopName} data={shops[shopName]} date={date}/>
                        </Grid>
                    )
                })}
            </Grid>
        </React.Fragment>
    );
}

export default ManagerDashboard;