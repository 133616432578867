/**
 * Created by Robin on 27/02/2023.
 */

import React, {useContext, useState, useEffect, useRef} from "react";

import { useReactToPrint } from 'react-to-print';

import {Link, Outlet, useParams} from "react-router-dom";

import { useTheme } from '@mui/material/styles';

import APIContext from "./../contexts/APIContext.js";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Toolbar from '@mui/material/Toolbar';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';

import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import RegisterLine from './../components/RegisterLine';
import RegisterDrawerContent from './../components/RegisterDrawerContent';
import CheckoutDialog from './../components/CheckoutDialog';

import PrintReceipt from './../components/PrintReceipt';

const drawerWidth = "400px";

function CashRegister(props) {
    const theme = useTheme();
    const API = useContext(APIContext);
    const { cartNo } = useParams();
    const [printReceipt, setPrintReceipt] = useState(null);
    const printContentRef = useRef(null);
    const printButtonRef = useRef(null);
    const [footer, setFooter] = useState("");
    const [lastPriceInput, setLastPriceInput] = useState(null);


    const [activeReceipt, setActiveReceipt] = useState(null);
    const [showCheckoutDialog, setShowCheckoutDialog] = useState(false);

    /*
    This fixes a bug that after printing the hidden iframe with print content retains focus
    Because the iframe has focus the onkeydown event is not triggered (when pressing star "*")
     */
    const unfocusIFrame = () => {
        if (document.activeElement.tagName === "IFRAME") {
            document.activeElement.blur();
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            unfocusIFrame();
        }, 1000);

        return () => {
            clearInterval(interval);
        }
    }, []);

    useEffect(() => {
        API.getActiveReceipt(cartNo).then((receipt) => {
            setActiveReceipt(receipt);
        });

        API.getFooter().then((footer) => {
            if (footer) {
                setFooter(footer);
            }
        });
    },[cartNo]);

    useEffect(() => {
        if (printReceipt && printButtonRef.current) {
            printButtonRef.current.click();
        }
    },[printReceipt]);

    useEffect(() => {
        if (lastPriceInput) {
            lastPriceInput.focus();
        }
    }, [lastPriceInput]);

    const handlePrint = useReactToPrint({
        content: () => printContentRef.current,
    });

    const setLastLinePriceInput = (input) => {
        if (input && lastPriceInput !== input) {
            setLastPriceInput(input);
        }
    };

    const finalize = async () => {
        setShowCheckoutDialog(false);

        let printReceipt = await API.updateReceipt(activeReceipt.id, {
            state: 'CLOSED',
            closingDate: new Date()
        });

        setPrintReceipt(printReceipt);

        let receipt = await API.getActiveReceipt(cartNo);
        if (receipt) {
            setActiveReceipt(receipt);
        }
    };

    const addPayment = async (method, amount) => {
        let receipt = await API.addPaymentToReceipt(activeReceipt.id, method, amount);
        if (receipt) {
            setActiveReceipt(receipt);
        }
    };

    const removePayment = async (paymentId) => {
        let receipt = await API.removePaymentFromReceipt(activeReceipt.id, paymentId);
        if (receipt) {
            setActiveReceipt(receipt);
        }
    };

    const updateReceipt = async (data) => {
        let res = await API.updateReceipt(activeReceipt.id, data);

        if (res) {
            setActiveReceipt(res);
        }
    };

    const updateValue = async (index, lineId, key, value) => {
        const res = await API.updateReceiptLine(activeReceipt.id, lineId, key, value);
        if (res) {
            setActiveReceipt(res);
        } else {
            console.error("FAILED TO UPDATE LINE");
        }
    };

    const createLine = async (key, value) => {
        if (key === 'productGroupInternalNo') {
            const res = await API.createReceiptLine(activeReceipt.id, value);

            if (res) {
                setActiveReceipt(res);
            }
        }
    };

    const deleteLine = async (lineId) => {
        const res = await API.deleteReceiptLine(activeReceipt.id, lineId);

        if (res) {
            setActiveReceipt(res);
        }
    };

    const renderLines = () => {
        let items = [];

        if (activeReceipt) {
            let index = 0;
            for (let line of activeReceipt.receipt_lines) {
                const idx = index++;
                const lineId = line.id;

                let priceInputRef = undefined;
                if (idx + 1 === activeReceipt.receipt_lines.length) {
                    priceInputRef = setLastLinePriceInput;
                }

                items.push(
                    <RegisterLine
                        key={line.id}
                        data={line}
                        priceInputRef={priceInputRef}
                        onValueChange={(key, value) => updateValue(idx, lineId, key, value)}
                        onDelete={(lineId) => deleteLine(lineId)}
                    />
                );
            }

            if (activeReceipt.state === 'OPEN') {
                items.push(<RegisterLine key="new" creationMode={true} onValueChange={(key, value) => createLine(key, value)}/>);
            }
        }

        return items;
    };

    return (
        <React.Fragment>
            <Card style={{marginLeft: drawerWidth}}>
                <CardContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Artikel</TableCell>
                                <TableCell>Aantal</TableCell>
                                <TableCell>Eenheidsprijs</TableCell>
                                <TableCell>Korting (%)</TableCell>
                                <TableCell>Subtotaal</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {renderLines()}
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
            <Drawer
                open
                anchor="left"
                variant="permanent"
                sx={{
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        width: drawerWidth,
                        backgroundColor: 'white',
                        color: theme.palette.primary.main
                    }
                }}
                style={{ zIndex: 1250 }}
            >
                <Toolbar />
                <Box style={{padding: 20, height: '100%'}}>
                    <RegisterDrawerContent receipt={activeReceipt} onDiscountChange={(value) => {updateReceipt({discount_percentage: value})}} onOpenCheckout={() => {setShowCheckoutDialog(true)}}/>
                    <CheckoutDialog
                        open={showCheckoutDialog}
                        onClose={() => setShowCheckoutDialog(false)}
                        receipt={activeReceipt}
                        onFinalize={finalize}
                        onAddPayment={addPayment}
                        onRemovePayment={removePayment}
                    />
                </Box>
                <Box style={{display: 'none'}}>
                    <button onClick={handlePrint} ref={printButtonRef}>HIDDEN PRINT</button>
                    <PrintReceipt receipt={printReceipt} contentRef={printContentRef} footer={footer} />
                </Box>
            </Drawer>
        </React.Fragment>
    )
}

export default CashRegister;