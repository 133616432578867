/**
 * Created by Robin on 28/02/2023.
 */

import React, {useContext, useState, useEffect} from "react";

import APIContext from "./../../contexts/APIContext.js";

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

function CountField(props) {
    const API = useContext(APIContext);

    const [value, setValue] = useState(1);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const safeSetValue = (value) => {
        value = parseInt(value);

        if (isNaN(value)) {
            value = 0;
        }

        setValue(value);

        if (typeof props.onChange === 'function') {
            props.onChange(value);
        }
    };

    return (
        <React.Fragment>
            <TextField
                value={value}
                onChange={(e) => safeSetValue(e.target.value)}
                style={{width: 160}}
                InputProps={{
                    inputProps: {
                        style: {textAlign: 'center'}
                    },
                    startAdornment:
                        <IconButton onClick={(e) => {
                            if (e.clientX !== 0) {
                                safeSetValue(value - 1);
                            }
                        }} onKeyDown={(e) => {
                            e.preventDefault();
                        }
                        }>
                            <RemoveIcon />
                        </IconButton>,
                    endAdornment:
                        <IconButton onClick={(e) => {
                            if (e.clientX !== 0) {
                                safeSetValue(value + 1);
                            }
                        }} onKeyDown={(e) => {
                            e.preventDefault();
                        }}>
                            <AddIcon />
                        </IconButton>,
                }}
            />
        </React.Fragment>
    );
}

export default CountField;