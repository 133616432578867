/**
 * Created by Robin on 28/02/2023.
 */

import React, {useContext, useState, useEffect} from "react";

import APIContext from "./../../contexts/APIContext.js";

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import ProductSearchDialog from './ProductSearchDialog.js';

function ProductGroupSelector(props) {
    const API = useContext(APIContext);

    const [showDialog, setShowDialog] = useState(false);
    const [value, setValue] = useState("");
    const [display, setDisplay] = useState(null);

    useEffect(() => {
        document.addEventListener("keydown", detectStarKeyPress);
        return () => {
            document.removeEventListener("keydown", detectStarKeyPress);
        }
    }, []);

    useEffect(() => {
        if (value && value !== "") {
            API.searchProducts(value).then((products) => {
               if (products.length === 1) {
                    setDisplay(products[0].name);
               } else {
                   setDisplay(null);
               }
            });
        }
    }, [value]);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    useEffect(() => {
        setValue(props.value);
    }, [props.creationMode]);

    const getDisplay = () => {
        if (value) {
            if (display) {
                return value + ' - ' + display;
            } else {
                return value;
            }
        } else {
            return '';
        }
    };

    const detectStarKeyPress = (event) => {
        if (props.creationMode)  {
            if (event.key === '*') {
                setShowDialog(true);
                event.preventDefault();
            }
        }
    };

    const innerSetValue = (value) => {
        //setValue(value);

        if (typeof props.onChange === 'function') {
            props.onChange(value);
        }
    };

    const openProductSearch = () => {
        setShowDialog(true);
    };

    return (
        <React.Fragment>
            <TextField
                label="Artikel"
                InputProps={{
                    endAdornment: <SearchIcon />,
                    readOnly: true
                }}
                onClick={openProductSearch}
                value={getDisplay()}
            />
            <ProductSearchDialog open={showDialog} onClose={() => setShowDialog(false)} onChange={(value) => innerSetValue(value)} />
        </React.Fragment>
    );
}

export default ProductGroupSelector;