/**
 * Created by Robin on 01/03/2023.
 */

import React, {useContext, useState, useEffect} from "react";

import APIContext from "./../../contexts/APIContext.js";

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';

import ProductGroupSelector from './../../components/ProductGroupSelector';
import CountField from './../../components/CountField';
import PriceField from './../../components/PriceField';
import DiscountSelector from './../../components/DiscountSelector';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import DeleteConfirmDialog from './DeleteConfirmDialog.js';

function RegisterLine(props) {
    const API = useContext(APIContext);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const onValueChange = (key, value) => {
        if (props.onValueChange) {
            props.onValueChange(key, value);
        }
    };

    if (props.creationMode) {
        return (
            <React.Fragment>
                <TableRow>
                    <TableCell>
                        <ProductGroupSelector
                            onChange={(value) => {
                                onValueChange('productGroupInternalNo', value)
                            }}
                            creationMode={props.creationMode}
                        />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>
                <TableRow>
                    <TableCell><ProductGroupSelector value={props.data.productGroupInternalNo} onChange={(value) => {
                        onValueChange('productGroupInternalNo', value)
                    }}/></TableCell>
                    <TableCell><CountField value={props.data.qty} onChange={(value) => {
                        onValueChange('qty', value)
                    }}/></TableCell>
                    <TableCell><PriceField value={props.data.unit_price} onChange={(value) => {
                        onValueChange('unit_price', value)
                    }} inputRef={props.priceInputRef}/></TableCell>
                    <TableCell><DiscountSelector value={props.data.discount_percentage} onChange={(value) => {
                        onValueChange('discount_percentage', value)
                    }}/></TableCell>
                    <TableCell><PriceField value={props.data.line_total} readOnly/></TableCell>
                    <TableCell>
                        <IconButton onClick={() => setShowDeleteDialog(true)}><DeleteForeverIcon /></IconButton>
                        <DeleteConfirmDialog open={showDeleteDialog} onClose={() => {setShowDeleteDialog(false)}} onDelete={() => {props.onDelete(props.data.id); setShowDeleteDialog(false)}}/>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }
}

export default RegisterLine;