/**
 * Created by Robin on 13/03/2023.
 */

import React, {useContext, useState, useEffect} from "react";

import moment from 'moment';
import 'moment/locale/nl';

import { useTheme } from '@mui/material/styles';

import APIContext from "./../contexts/APIContext.js";

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import HistoryLine from './../components/HistoryLine';
import ShopSelector from './../components/ShopSelector';
import CheckoutDialog from './../components/CheckoutDialog';

import PaymentIcon from '@mui/icons-material/Payment';
import EuroIcon from '@mui/icons-material/Euro';

import MonthOverview from './../components/MonthOverview';

function ManagerMonthStats(props) {
    const API = useContext(APIContext);
    const [data, setData] = useState(null);

    useEffect(() => {
        API.getShopTotals().then((res) => {
            setData(res);
            console.log(res);
        });
    }, []);

    if (data) {
        return (
            <Card>
                <CardHeader title="Maandtotalen" />
                <MonthOverview data={data} />
            </Card>
        );
    } else {
        return [];
    }
}

export default ManagerMonthStats;