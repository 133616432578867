/**
 * Created by Robin on 03/03/2023.
 */

import React, {useContext, useState, useEffect} from "react";

import Button from '@mui/material/Button';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import APIContext from "./../../contexts/APIContext.js";

import PaymentIcon from '@mui/icons-material/Payment';
import EuroIcon from '@mui/icons-material/Euro';

import ReceiptViewDialog from './ReceiptViewDialog';

function HistoryLine(props) {
    const API = useContext(APIContext);
    const [showReceipt, setShowReceipt] = useState(false);

    const formatValue = (value) => {
        return new Intl.NumberFormat('fr-BE', { style: 'decimal', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
    };

    return (
        <TableRow>
            <TableCell><Button onClick={() => {setShowReceipt(true)}}>{props.receipt.no}</Button></TableCell>
            <TableCell>{new Date(props.receipt.closingDate).toLocaleString('nl-BE')}</TableCell>
            {props.extended &&
                <TableCell>{props.receipt.shop.name}</TableCell>
            }
            <TableCell align="right">€ {formatValue(props.receipt.total)}</TableCell>

            <ReceiptViewDialog open={showReceipt} onClose={() => setShowReceipt(false)} id={props.receipt.id}/>
        </TableRow>
    )
}

export default HistoryLine;