/**
 * Created by Robin on 02/03/2023.
 */

import React, {useContext, useState, useEffect} from "react";

import APIContext from "./../../contexts/APIContext.js";

import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import PaymentIcon from '@mui/icons-material/Payments';

import DiscountSelector from './../DiscountSelector'

function RegisterDrawerContent(props) {
    const API = useContext(APIContext);

    const openCheckoutDialog = () => {
        if (typeof props.onOpenCheckout === 'function') {
            props.onOpenCheckout();
        }
    };

    const updateDiscount = async (value) => {
        console.log("Update discount");
        if (typeof props.onDiscountChange === 'function') {
            props.onDiscountChange(value);
        }
    };

    const getItemCount = () => {
        let qty = 0;

        for (let line of props.receipt.receipt_lines) {
            qty += line.qty
        }

        return qty;
    };

    const formatValue = (value) => {
        return new Intl.NumberFormat('fr-BE', { style: 'decimal', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
    };

    if (props.receipt) {
        let itemCount = getItemCount();
        return (
            <React.Fragment>
                <Grid container direction="column" justifyContent="space-between" style={{height: '100%'}}>
                    <Grid item>
                        <Typography variant="h3" textAlign="center">
                            {itemCount + (itemCount === 1 ? ' Artikel' : ' Artikelen')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h5" textAlign="center">
                            Totaal zonder korting
                        </Typography>
                        <Typography variant="h5" textAlign="center">
                            € {formatValue(props.receipt.total_without_discount)}
                        </Typography>
                    </Grid>
                    <Grid item style={{alignSelf: "center"}}>
                        <Typography variant="h5" textAlign="center">
                            Korting
                        </Typography>
                        <DiscountSelector value={props.receipt.discount_percentage} onChange={(value) => {updateDiscount(value)}}/>
                    </Grid>
                    <Grid item>
                        <Typography variant="h3" textAlign="center" style={{paddingBottom: 10}}>
                            € {formatValue(props.receipt.total_rounded)}
                        </Typography>
                        <Button variant="contained" startIcon={<PaymentIcon />} disabled={props.receipt.receipt_lines.length === 0} fullWidth onClick={openCheckoutDialog}>Afrekenen</Button>
                    </Grid>
                </Grid>

            </React.Fragment>
        );
    } else {
        return [];
    }
}

export default RegisterDrawerContent;