/**
 * Created by Robin on 28/02/2023.
 */

import React, {useContext, useState, useEffect} from "react";

import APIContext from "./../../contexts/APIContext.js";

import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

function ProductSearchDialog(props) {
    const API = useContext(APIContext);

    const renderDiscounts = () => {
        let items = [];

        items.push(
            <Grid item xs={6} md={4} lg={3} key={0}>
                <Box style={{border: '1px solid #aaa', padding: 10, cursor: 'pointer'}} onClick={() => {props.onChange(0); props.onClose();}}>
                    <Typography align="center"><b>{0}%</b></Typography>
                </Box>
            </Grid>
        );

        items.push(
            <Grid item xs={6} md={4} lg={3} key={5}>
                <Box style={{border: '1px solid #aaa', padding: 10, cursor: 'pointer'}} onClick={() => {props.onChange(5); props.onClose();}}>
                    <Typography align="center"><b>{5}%</b></Typography>
                </Box>
            </Grid>
        );

        for (let i = 10; i <= 70; i=i+10) {
            items.push(
                <Grid item xs={6} md={4} lg={3} key={i}>
                    <Box style={{border: '1px solid #aaa', padding: 10, cursor: 'pointer'}} onClick={() => {props.onChange(i); props.onClose();}}>
                        <Typography align="center"><b>{i}%</b></Typography>
                    </Box>
                </Grid>
            )
        }

        return items;
    };

    return (
        <Dialog open={props.open} fullWidth={true} maxWidth={false} PaperProps={{style: { maxHeight: '90vh', minHeight: '90vh'}}} onClose={props.onClose}>
            <DialogContent>
                <Box style={{marginTop: 40}}>
                    <Grid container spacing={2}>
                        {renderDiscounts()}
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onClose()}>Annuleren</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ProductSearchDialog;