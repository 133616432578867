/**
 * Created by Robin on 27/02/2023.
 */

import React from 'react';

const APIContext = React.createContext({});
APIContext.displayName = 'APIContext';

export default APIContext;
