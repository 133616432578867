/**
 * Created by Robin on 03/03/2023.
 */

import React, {useContext, useState, useEffect} from "react";

import moment from 'moment';
import 'moment/locale/nl';

import { useTheme } from '@mui/material/styles';

import APIContext from "./../contexts/APIContext.js";

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import HistoryLine from './../components/HistoryLine';
import ShopSelector from './../components/ShopSelector';
import CheckoutDialog from './../components/CheckoutDialog';

import PaymentIcon from '@mui/icons-material/Payment';
import EuroIcon from '@mui/icons-material/Euro';

moment.updateLocale("nl", {
    week: {
        dow: 1
    }
});

function ManagerHistory(props) {
    const theme = useTheme();
    const API = useContext(APIContext);
    const [date, setDate] = useState(moment());
    const [selectedShop, setSelectedShop] = useState("");

    const [receipts, setReceipts] = useState([]);

    useEffect(() => {
        API.getHistory(date.format('YYYY-MM-DD'), undefined).then((receipts) => {
            setReceipts(receipts);
        });
    },[]);

    useEffect(() => {
        API.getHistory(date.format('YYYY-MM-DD'), selectedShop !== "" ? selectedShop : undefined).then((receipts) => {
            setReceipts(receipts);
        });
    },[date, selectedShop]);

    const renderLines = () => {
        let items = [];

        for (let receipt of receipts) {
            items.push(
                <HistoryLine receipt={receipt} key={receipt.id} extended />
            );
        }

        return items;
    };

    return (
        <Card>
            <CardHeader
                title="Dagtotalen"
                action={
                    <Grid container spacing={2}>
                        <Grid item>
                            <Box style={{width: '200px'}}>
                                <ShopSelector value={selectedShop} onChange={(e) => setSelectedShop(e.target.value)}/>
                            </Box>
                        </Grid>
                        <Grid item>
                            <DatePicker format="DD/MM/YYYY" value={date} onChange={(value) => setDate(value)}/>
                        </Grid>
                    </Grid>
                }
            />
            <CardContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>No</TableCell>
                            <TableCell>Datum</TableCell>
                            <TableCell>Winkel</TableCell>
                            <TableCell align="right">Totaal bedrag</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderLines()}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    );
}

export default ManagerHistory