/**
 * Created by Robin on 06/03/2023.
 */

import React, {useContext, useState, useEffect} from "react";

import logo from '../../img/logo.jpg';

const RECEIPT_WIDTH_IN_CM = 7.8;

function PrintReceipt(props) {
    let body = document.body;
    let html = document.documentElement;


    const formatValue = (value) => {
        return new Intl.NumberFormat('fr-BE', { style: 'decimal', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
    };

    const productCount = () => {
        let count = 0;
        for (let line of props.receipt.receipt_lines) {
            count += line.qty;
        }

        if (count === 1) {
            return "1 Artikel";
        } else {
            return count.toString() + " Artikelen";
        }
    };

    const calculateChange = () => {
        let change = parseFloat(props.receipt.total_rounded);

        for (let payment of props.receipt.payments) {
            change = change - parseFloat(payment.amount);
        }

        return Math.abs(change);
    };

    if (props.receipt) {
        return (
            <div style={{width: RECEIPT_WIDTH_IN_CM + 'cm'}} ref={props.contentRef}>
                <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                    <img src={logo} style={{width: '150px'}}/>
                </div>
                <div style={{marginTop: 20, marginBottom: 20}}>
                    <p style={{padding: 0, margin: 0}}>{new Date(props.receipt.closingDate).toLocaleString('nl-BE')}</p>
                    <p style={{padding: 0, margin: 0}}>{props.receipt.no}</p>
                </div>
                <div>
                    <table style={{width: '100%'}}>
                        <tbody>
                        {
                            props.receipt.receipt_lines.map((line) => {
                                return (
                                    <React.Fragment key={line.id}>
                                        <tr>
                                            <td>
                                                {line.qty} x {line.product_group.name}
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td style={{paddingLeft: 15}}>
                                                {line.productGroupInternalNo} {line.discount_percentage !== 0 ? ('(KORTING ' + line.discount_percentage + '%)') : ''}
                                            </td>
                                            <td align="right">
                                                {'€' + formatValue(line.line_total)}
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                );
                            })
                        }
                        </tbody>
                    </table>
                </div>
                <div style={{marginTop: 10, marginBottom: 10}}>
                    <p>{productCount()}</p>
                </div>
                <div>
                    <table style={{width: '100%'}}>
                        <tbody>
                        {props.receipt.discount_percentage !== 0 &&
                        <React.Fragment>
                            <tr>
                                <td style={{fontSize: 18}}>Totaal zonder korting</td>
                                <td style={{fontSize: 18}}
                                    align="right">{'€' + formatValue(props.receipt.total_without_discount)}</td>
                            </tr>
                            <tr>
                                <td>Korting</td>
                                <td align="right">{props.receipt.discount_percentage + '%'}</td>
                            </tr>
                            <tr>
                                <td style={{fontSize: 18}}>Totaal</td>
                                <td style={{fontSize: 18}}
                                    align="right">{'€' + formatValue(props.receipt.total_rounded)}</td>
                            </tr>
                        </React.Fragment>
                        }
                        {props.receipt.discount_percentage === 0 &&
                        <React.Fragment>
                            <tr>
                                <td style={{fontSize: 18}}>Totaal</td>
                                <td style={{fontSize: 18}}
                                    align="right">{'€' + formatValue(props.receipt.total_rounded)}</td>
                            </tr>
                        </React.Fragment>
                        }
                        </tbody>
                    </table>
                </div>
                <div>
                    <table style={{width: '100%'}}>
                        <tbody>
                        {props.receipt.payments.map((payment) => {
                            return (
                                <tr key={payment.id}>
                                    <td>{payment.payment_method}</td>
                                    <td align="right">{'€' + formatValue(payment.amount)}</td>
                                </tr>
                            )
                        })}
                        <tr>
                            <td />
                            <td />
                        </tr>
                        <tr>
                            <td>Wisselgeld</td>
                            <td align="right">{'€' + formatValue(calculateChange())}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div style={{textAlign: 'center', marginTop: 20}}>{props.footer ? props.footer : ''}</div>
                <style>{`
            @page {
                margin: 10px;
                size: 7.8cm 20cm;
            }
            `}</style>
            </div>
        );
    } else {
        return [];
    }
}

export default PrintReceipt;